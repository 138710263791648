<template>
  <div class="complaint-page">
    <div class="content">
      <h1 class="header">净网声明</h1>
      <div class="input-line">
        <p>
          净网行动是一场及时雨，与弘扬网络正能量行动一样，都是保护和推动互联网健康发展的积极举措。
        </p>
        <p>
          我们非常支持国家和行业对网上有害信息的治理，愿意积极响应并严格自律，与行业同仁携手共建一片清朗的网络天空。
        </p>
      </div>
      <div class="menu">
        <ul>
          <li><router-link to="/main/about/policy">免责声明</router-link></li>
          <li><router-link to="/main/about/agree">用户协议</router-link></li>
          <li><router-link to="/main/about/version">版权说明</router-link></li>
          <li><router-link to="/main/about/safe">净网声明</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.complaint-page {
  min-height: 100%;
  background-color: #f8f8f8;
}
.content {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px 40px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 15px;
  line-height: 1.6;
  text-align: center;
  word-break: break-all;
  .complaint-image {
    width: 60px;
    margin-bottom: 10px;
  }
  .header {
    margin-bottom: 10px;
  }
  .input-line {
    text-align: left;
    margin-bottom: 15px;
  }
  .menu {
    width: 100%;
    border-top: 1px solid $theme-color;
    height: 40px;
    margin-top: 50px;
    ul {
      margin: 0px;
      list-style: none;
      li {
        float: right;
        margin-left: 30px;
        a {
          color: $theme-color;
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .content {
    padding: 20px;
  }
}
</style>
